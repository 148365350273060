import {
  Button,
  Flex,
  Heading,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import {
  ApiError,
  LandingPageService,
  PembayaranService,
  model_PaketResponse,
} from '@/api';
import { TextInput } from '@/components/inputs';
import { useAuth } from '@/context/AuthContext';
import Navigation from '@/modules/landing/components/navigation';

export default function KalkulasiPaket() {
  const { id_paket } = useParams();
  const num_id_paket = Number(id_paket);
  const [paket, setPaket] = useState<model_PaketResponse>();
  const toast = useToast();

  const { user } = useAuth();

  const id_sekolah = user?.user?.id_sekolah ?? 0;

  const [pembayaran, setPembayaran] = useState<'bulan' | 'tahun'>('bulan');
  const [jumlahUser, setJumlahUser] = useState(0);
  const [inputValue, setInputValue] = useState(jumlahUser);

  const updateJumlahUser = (value: number) => {
    if (isNaN(value) || value < 0) {
      setJumlahUser(0);
      setInputValue(0);
    } else if (value < parseInt(paket?.min_user ?? '0')) {
      toast({
        title: `Jumlah user minimal ${parseInt(paket?.min_user ?? '0')}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setJumlahUser(parseInt(paket?.min_user ?? '0'));
      setInputValue(parseInt(paket?.min_user ?? '0'));
    } else if (value > 10000000) {
      toast({
        title: 'Jumlah user maksimal 10.000.000',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setJumlahUser(10000000);
      setInputValue(10000000);
    } else {
      setJumlahUser(value);
    }
  };

  useQuery(['pakets'], () => LandingPageService.getPilihanPaket(), {
    onSuccess: (data) => {
      const p = data.find((paket) => paket.id_paket === num_id_paket);
      setPaket(p);
    },
  });

  const bayarMutation = useMutation(() =>
    PembayaranService.createUrlTransactionWithGateway({
      idSekolah: id_sekolah,
      body: {
        id_paket: num_id_paket,
        skema_pembayaran: pembayaran === 'bulan' ? 1 : 2,
        quantity: jumlahUser,
      },
    })
  );

  const bayar = () => {
    bayarMutation.mutate(void {}, {
      onSuccess: (data) => {
        window.location.href = data.redirect_url;
      },
      onError: (error) => {
        toast({
          title: 'Gagal membuat transaksi',
          description: error instanceof ApiError ? error.message : undefined,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <>
      <Navigation />

      <Flex mx={'auto'} data-testid="content" p={8} h={'full'}>
        <Flex direction={'column'} mx={'auto'}>
          <Flex direction={'column'} gap={4}>
            <Heading
              textAlign={'center'}
              fontWeight="semibold"
              color="gray.800"
              fontSize={'5xl'}
            >
              Registrasi Paket
            </Heading>
            <Text fontSize={'xl'} fontWeight={'semibold'} textAlign={'left'}>
              Atur Skema
            </Text>
          </Flex>
          <Flex
            mt={6}
            gap={4}
            border={'1px solid'}
            borderColor={'gray.300'}
            borderRadius={8}
            w={'max-content'}
            mx={'auto'}
          >
            <Flex
              direction={'column'}
              gap={6}
              px={6}
              my={6}
              borderRight={'1px solid'}
              borderColor={'gray.300'}
            >
              <Flex direction={'column'} gap={2}>
                <Text fontSize={'xl'} fontWeight={'semibold'}>
                  {paket?.nama_paket}
                </Text>
                <Text color={'gray.600'}>{paket?.deskripsi_paket}</Text>
              </Flex>
              <Flex direction={'column'}>
                {paket?.fitur.map((f) => (
                  <Flex gap={2} key={f.id_fitur}>
                    <FiCheck color={'green.500'} />
                    <Text fontSize={'sm'} color={'gray.600'}>
                      {f.nama_fitur}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>

            <Flex
              flexGrow={1}
              direction={'column'}
              gap={6}
              px={6}
              my={6}
              w={'lg'}
            >
              <Text fontSize={'xl'} fontWeight={'semibold'} textAlign={'left'}>
                Skema Pemesanan
              </Text>
              <Flex direction={'column'} gap={2}>
                <Text fontSize={'lg'} textAlign={'left'}>
                  Skema Pembayaran
                </Text>
                {/* radio button for price */}
                <RadioGroup
                  defaultValue="bulan"
                  onChange={(e) => setPembayaran(e as 'bulan' | 'tahun')}
                >
                  <Flex gap={4}>
                    <Radio value="bulan" size="lg" ml={4}></Radio>
                    <Flex placeContent={'space-between'} flexGrow={1}>
                      <Text fontSize={'lg'} textAlign={'left'}>
                        Bulanan
                      </Text>
                      <Text fontSize={'lg'} textAlign={'left'}>
                        {new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                        }).format(parseInt(paket?.harga_perbulan ?? '0'))}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex gap={4}>
                    <Radio value="tahun" size="lg" ml={4}></Radio>
                    <Flex placeContent={'space-between'} flexGrow={1}>
                      <Text fontSize={'lg'} textAlign={'left'}>
                        Tahunan
                      </Text>
                      <Text fontSize={'lg'} textAlign={'left'}>
                        {new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                        }).format(parseInt(paket?.harga_pertahun ?? '0'))}
                      </Text>
                    </Flex>
                  </Flex>
                </RadioGroup>
              </Flex>
              <Flex direction={'column'} gap={2}>
                <Text fontSize={'lg'} textAlign={'left'}>
                  Jumlah User{' '}
                  {parseInt(paket?.min_user ?? '0') > 0 && (
                    <Text as={'span'} color={'red.500'}>
                      * Minimal {parseInt(paket?.min_user ?? '0')} user
                    </Text>
                  )}
                </Text>
                <InputGroup>
                  <TextInput
                    type={'number'}
                    onChange={(e) => {
                      const value = e.target.valueAsNumber;
                      setInputValue(value);
                    }}
                    onBlur={(e) => {
                      const value = e.target.valueAsNumber;
                      updateJumlahUser(value);
                    }}
                    value={inputValue}
                  />
                  <InputRightElement
                    bg={'gray.100'}
                    px={8}
                    border={'1px solid'}
                    borderColor={'gray.300'}
                    borderRadius={'0 0.375rem 0.375rem 0'}
                  >
                    <Text fontSize={'lg'} textAlign={'left'}>
                      user
                    </Text>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              <Flex
                direction={'row'}
                placeContent={'flex-end'}
                gap={2}
                flexGrow={1}
                placeItems={'flex-end'}
              >
                <Text fontSize={'xl'} textAlign={'left'}>
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(
                    parseInt(
                      pembayaran === 'bulan'
                        ? paket?.harga_perbulan ?? '0'
                        : paket?.harga_pertahun ?? '0'
                    ) * (isNaN(jumlahUser) ? 0 : jumlahUser)
                  )}
                </Text>
                <Text fontSize={'xl'} textAlign={'left'}>
                  / {pembayaran === 'bulan' ? 'Bulan' : 'Tahun'}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex placeContent={'flex-end'} my={14}>
            <Tooltip
              label={!user ? 'Silahkan login terlebih dahulu' : ''}
              fontSize="md"
            >
              <Button
                variant={'erp-primary'}
                onClick={bayar}
                isDisabled={!user}
              >
                Lanjutkan
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
