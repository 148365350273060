import { Box, Button, Flex, Link, Select, Text } from '@chakra-ui/react';

export default function Navigation() {
  return (
    <Box>
      {/* language select */}
      <Flex justifyContent="flex-end" py={1} px={24}>
        <Select
          w="200px"
          size={'sm'}
          onChange={(e) => {
            const value = e.target.value;
          }}
        >
          <option value="id">Indonesia</option>
        </Select>
      </Flex>
      <Box
        alignSelf="flex-start"
        width="full"
        flexDirection="column"
        px={5}
        py={3}
        mx="auto"
        backgroundColor="#d1e3ff"
      >
        <Flex
          alignSelf="center"
          width="full"
          maxW={{ base: 'full', xl: '90vw' }}
          placeContent={'space-between'}
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          mx={'auto'}
        >
          <Flex gap={5} my="auto">
            <Link href={'/'} _hover={{ textDecoration: 'none' }}>
              <Flex
                fontSize={{ base: '4xl', md: '6xl' }}
                fontWeight="semibold"
                maxW={{ base: 'full', md: '4xl' }}
              >
                <Text textShadow="2px 3px #808080" color="blue.600">
                  E
                </Text>
                <Text textShadow="2px 3px #808080" color="red.600">
                  D
                </Text>
                <Text textShadow="2px 3px #808080" color="orange.400">
                  U
                </Text>
              </Flex>
            </Link>
            <Flex
              alignSelf="center"
              width={{ base: 'full', md: '644px' }}
              maxW="full"
              placeItems={'start'}
              justify="between"
              gap={5}
              my="auto"
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              justifyContent={{ base: 'center', md: 'between' }}
              color="black"
              fontSize="xl"
              fontWeight="semibold"
            >
              <Link
                href={'/produk'}
                _hover={{ textDecoration: 'none', color: 'blue.600' }}
              >
                Produk
              </Link>
              <Link
                href={'/paket'}
                _hover={{ textDecoration: 'none', color: 'blue.600' }}
              >
                Paket Pembelian
              </Link>
              <Link
                href={'/panduan'}
                _hover={{ textDecoration: 'none', color: 'blue.600' }}
              >
                Panduan
              </Link>
              <Link
                href={'/tentang'}
                _hover={{ textDecoration: 'none', color: 'blue.600' }}
              >
                Tentang
              </Link>
            </Flex>
          </Flex>
          <Flex gap={5} my="auto">
            <Button variant="erp-secondary" px={8} borderRadius={10}>
              <Link href={'/login'} _hover={{ textDecoration: 'none' }}>
                Masuk
              </Link>
            </Button>
            <Button variant="erp-primary" px={8} borderRadius={10}>
              <Link href={'/registrasi'} _hover={{ textDecoration: 'none' }}>
                Registrasi
              </Link>
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}
